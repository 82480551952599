


































































































































import MovableTableComponent from "./MovableTableComponent";
export default MovableTableComponent;
