import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { SiteLinkedSession } from '@/Model/siteLocations/types';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';

@Component({
    components:{
        'progressButton': ProgressButton,
    }
})

export class SiteLinkedToSessionPopUp extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @PropSync('isSiteLinkedToSessionPopUpVisible') isSiteLinkedToSessionPopUpVisibleSync !: boolean;
  
    @Watch('isSiteLinkedToSessionPopUpVisible', { immediate: true })
    onIsVisible(isSiteLinkedToSessionPopUpVisible: boolean) {
      const isElementShowing = UIkit.modal(this.$el) ? UIkit.modal(this.$el).isToggled() : false;
      if (isSiteLinkedToSessionPopUpVisible && isElementShowing === false) {
        UIkit.modal(this.$el).show();
      }
      else if (isSiteLinkedToSessionPopUpVisible === false && isElementShowing) {
        UIkit.modal(this.$el).hide();
      }
    }

    @Prop()
    siteName!: string;

    @Prop()
    siteLinkedSessions!: Array<SiteLinkedSession>;
    
    getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    closePopup(){
        this.isSiteLinkedToSessionPopUpVisibleSync = false;
    }
}