import API_CONST from '@/constants/ApiConst';
import { SiteLocation, VerifiedLocation, Location, GetSiteLocationsRequest, GetSiteLocationsResponse, DeleteSiteLocationRequest, GetAllSiteLocationsRequest } from '@/Model/siteLocations/types';
import { httpClient } from '../common/httpClient';

export async function addNewLocation(newLocation: Location): Promise<{data: VerifiedLocation}>  {
  const { addressLine1, addressLine2, city, stateCode, zip } = newLocation;
  return httpClient().post(`${API_CONST.LOCATIONS_END_POINT}`, {
    addressLine1,
    addressLine2,
    city,
    stateCode,
    zip5: zip
  });
}

export function addNewSiteLocationForAccount(newSite: SiteLocation, accountId: number, locationId: number | null) {
    return httpClient().post(`${API_CONST.SITE_LOCATIONS_END_POINT}`, {
      siteLocationName: newSite.siteLocationName,
      locationId,
      accountId
    });
}

 export async function updateSiteLocationForAccount(newSite: SiteLocation, accountId: number, locationId: number | null) {
  return await httpClient().put(`${API_CONST.SITE_LOCATIONS_END_POINT}`, {
    id: newSite.id,
    siteLocationName: newSite.siteLocationName,
    locationId,
    accountId
  });
}

export function getSiteLocationsForAccount(getSiteLocationsRequest: GetSiteLocationsRequest): Promise<{data: GetSiteLocationsResponse}> {
  const url = `${API_CONST.AVAILABLE_SITE_LOCATIONS_END_POINT}` +
    `?accountId=${encodeURIComponent(getSiteLocationsRequest.id)}` +
    `&page=${encodeURIComponent(getSiteLocationsRequest.page || '')}` +
    `&sortField=${encodeURIComponent(getSiteLocationsRequest.sortField || '')}` +
    `&sortDir=${encodeURIComponent(getSiteLocationsRequest.sortDir || '')}` +
    `&searchField=${encodeURIComponent(getSiteLocationsRequest.searchField || '')}` +
    `&search=${encodeURIComponent(getSiteLocationsRequest.search || '')}`;
  return httpClient().get(url);
}

export function getAllSiteLocationsForAccount(request: GetAllSiteLocationsRequest) {
  const maxAllowedItemCount = 2147483647; // Max value of int32, so we can get all items as one "page" to be able to get the full list from the backend

  let url = `${API_CONST.AVAILABLE_SITE_LOCATIONS_END_POINT}` +
    `?accountId=${encodeURIComponent(request.id)}` +
    `&pageItemCount=${maxAllowedItemCount}` +
    `&maximumItemsPerPage=${maxAllowedItemCount}`;

  if (request.sortField && request.sortDir) {
    url += `&sortField=${request.sortField}&sortDir=${request.sortDir}`;
  }

  return httpClient().get<GetSiteLocationsResponse>(url);
}

export function softDeleteSiteLocation(deleteSiteLocationRequest: DeleteSiteLocationRequest) {
  return httpClient().delete(`${API_CONST.SITE_LOCATIONS_END_POINT}/${deleteSiteLocationRequest.accountId}/${deleteSiteLocationRequest.siteId}`)
}