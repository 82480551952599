import { Vue, Component, Prop } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText';
import table from '@/store/modules/table';
import APP_CONST from '@/constants/AppConst';

@Component
export class ActionsDropdown extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public toggleDropDown: boolean = false;
    public dropdownElement: any = {};
    public active: number = -1;
    public counter: number = 0;
    public activeLetter: string = '';
    public activeElement: string = '';
    public callToggle: boolean = false;

    @Prop()
    dropdownList!: []

    @Prop()
    content!: []

    get dropdownLists() {
        this.dropdownList &&
            this.dropdownList.length &&
            this.dropdownList.forEach((element: any) => {
                if (element.name == 'Add') {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? true : false;
                } else if (element.name == 'Upload CSV') {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? true : false;
                } else if (element.name == APP_CONST.ADD_PRIVILEGES_ACTION || element.name == APP_CONST.SEND_INVITATION_ACTION || element.name == APP_CONST.RESET_PASSWORD_ACTION ) {
                    element.disable = element.disable
                } else if (element.name == 'Enable'){
                    element.disable = table.selectedStatus == 'Enable' ? false : table.selectedStatus == 'All' ? true : true;
                } else if (element.name == 'Disable') {
                    element.disable = table.selectedStatus == 'Disable' ? false : table.selectedStatus == 'All' ? true : true;
                } else {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? false : true;
                }
            })
        return this.dropdownList;
    }

    get allDataSelected() {
        return table.allDataSelected;
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    toggleDrop() {
        this.toggleDropDown = !this.toggleDropDown
        this.$emit('openActionDropdown')
    }

    dropDownHandler(keyName: string, event?: any) {
        this.toggleDropDown = false;
        if (event && (event.keyCode == 13 || event.keyCode == 40 || event.keyCode == 9 || event.keyCode == 27)) {
            this.active = -1;
            event && event.target && event.target.blur();
            event && event.preventDefault();
        }

        this.$emit('performAction', keyName)
    }


    openDropdown(event: any) {
        if (event.keyCode == 13 || event.keyCode == 40) {
            this.toggleDropDown = true;
            this.active = -1;
        }
    }
    closeDropdown(event: any) {
        if (event && (event.keyCode == 13 || event.keyCode == 40 || event.keyCode == 9 || event.keyCode == 27)) {
            this.toggleDropDown = false;
            this.active = -1;
            event.target && event.target.focus();
        }
    }

    onDropdownOpen(event: any) {
        event.stopPropagation();
        this.dropdownElement = document.querySelector('#dropdown-menu')
        let dropdownMenuItems = this.dropdownElement && this.dropdownElement.children
        if (this.toggleDropDown) {
            event && event.target && event.target.blur();
            const input = String.fromCharCode(event.keyCode);
            if (event.keyCode == 40) {
                event.preventDefault();
                if (this.active == -1) {
                    this.active++;
                    dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
                    dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
                } else if (this.dropdownElement && this.active < this.dropdownElement.children.length - 1) {
                    this.active++;
                    dropdownMenuItems[this.active] && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
                    dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
                    dropdownMenuItems.forEach((drop: any, index: number) => {
                        if (index != this.active) {
                            dropdownMenuItems[index].classList.remove('dropbtn-selected');
                        }
                    })

                }
                } else if (event.keyCode == 38) {
                event.preventDefault();
                if (this.active > 0) {
                    this.active--;
                    if (this.active != -1 && this.active != 0) {
                        dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
                        dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
                        dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
                            if (index != this.active) {
                                dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
                            }
                        })

                    } else if (this.active == 0) {
                        dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
                        dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
                        dropdownMenuItems.forEach((drop: any, index: number) => {
                            if (index != this.active) {
                                dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
                            }
                        })
                    }
                }
            }
            else if(event.keyCode == 32)
            {
                event.preventDefault();
            }
            else if (event.keyCode == 13) {
                let list: any = this.dropdownLists;
                event.preventDefault();
                if (this.active != -1 && list[this.active] && !list[this.active].disable) {
                    this.dropDownHandler(list[this.active].name, event);
                    dropdownMenuItems.forEach((drop: any, index: number) => {
                        if (index != this.active) {
                            dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
                        }
                    })
                    this.toggleDropDown = false;
                }else if(event.keyCode == 13 && this.active == -1){
                    this.closeDropdown(event);
                }
                else{
                    event.target && event.target.focus();
                }
            } else if (event.keyCode == 9) {
                this.closeDropdown(event)
                dropdownMenuItems.forEach((drop: any, index: number) => {
                    if (index != this.active) {
                        dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
                    }
                })
            } else if (event.keyCode == 27) {
                let escFocus =  document.getElementById("focusSpan")
                escFocus && escFocus.focus();
                this.toggleDropDown = APP_CONST.FALSE;
                this.active = -1;
                dropdownMenuItems.forEach((drop: any, index: number) => {
                    if (index != this.active) {
                        dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
                    }
                })
            }
            else if (/[a-zA-Z0-9]/.test(input)) {
                let iterationCount:number = 1;
                let i:number = this.active;
                while(iterationCount!=this.dropdownElement.children.length){
                   if(++i==this.dropdownElement.children.length)
                    i=0
                   let val = dropdownMenuItems[i].innerText.charAt(0).toLowerCase();
                   let inp = input.toLowerCase();
                   if(val == inp){
                      let dropdownMenuItems = this.dropdownElement.children;
                      this.active = i;
                      dropdownMenuItems[this.active].classList.add('dropbtn-selected');
                      dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
                      dropdownMenuItems.forEach((drop: any, index: number) => {
                         if (index != this.active) {
                            dropdownMenuItems[index].classList.remove('dropbtn-selected');
                         }
                      })
                      break;
                   }
                   iterationCount++;
                   event.target && event.target.focus()
                }
            }
        } else {
            event.target && event.target.focus();
            if (event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 32) {
                event.preventDefault();
                this.toggleDropDown = true;
            } else if (event.keyCode == 13) {
                this.toggleDropDown = true;
            }

        }
        dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].focus();
    }

    hoverState(index: number) {
        this.dropdownElement = document.querySelector('#dropdown-menu');
        let dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
        this.active = index;
        if (this.active == 0) {
            dropdownMenuItems && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
            dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
                if (index != this.active) {
                    dropdownMenuItems[index].classList.remove('dropbtn-selected');
                }
            })
        }
        else if (this.active && this.active > -1) {
            dropdownMenuItems && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
            dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
                if (index != this.active) {
                    dropdownMenuItems[index].classList.remove('dropbtn-selected');
                }
            })

        }
    }

    removeClass() {
        this.active = -1;
        let dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
        dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
            if (index != this.active) {
                dropdownMenuItems[index].classList.remove('dropbtn-selected');
            }
        })
    }



}
