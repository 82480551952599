enum MutationNames {
    SAVE_SITE_REQUESTED = 'SAVE_SITE_REQUESTED',
    SAVE_SITE_SUCCEEDED = 'SAVE_SITE_SUCCEEDED',
    SAVE_SITE_FAILED = 'SAVE_SITE_FAILED',
    RESET_SAVE_SITE = 'RESET_SAVE_SITE',
    GET_SITES_REQUESTED = 'GET_SITES_REQUESTED',
    GET_SITES_SUCCEEDED = 'GET_SITES_SUCCEEDED',
    GET_SITES_FAILED = 'GET_SITES_FAILED',
    DELETE_SITE_REQUESTED = 'DELETE_SITE_REQUESTED',
    DELETE_SITE_SUCCEEDED = 'DELETE_SITE_SUCCEEDED',
    DELETE_SITE_FAILED = 'DELETE_SITE_FAILED',
    RESET_DELETE_SITE = 'RESET_DELETE_SITE'
}

export default MutationNames;