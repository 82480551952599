import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
   components: {
   }
})
export default class DisplayColumnFilter extends Vue {

   @Prop()
   displayColumn!: any;

   @Prop()
   componentName!: string

   @Prop()
   showSettings!: boolean;

   public displayAll: boolean = true;
   public allColumns:any =[];
   public activeIndex: number = -1
   public dropdownElementList:any={};
   public activeIndexLetter:string = '';
   public counter:number = 0;
   public activeIndexElement : string='';
   public callToggle:boolean = false;
   public tabPress: boolean=false;
   public enterPress: boolean=false;

   @Watch('displayColumn', { deep: true, immediate: true })
   displayedColumn(value:any){
      if (value) {
         this.displayColumn = value
      }
      let isSelected = true;
      this.displayColumn && this.displayColumn.length && this.displayColumn.forEach((column: any) => {
         if (!column.display) {
            isSelected = false;
         }
      });
      this.displayAll = isSelected;
   }

   checkAllColumns(column:any) {
      this.$emit("filterColumns", this.displayColumn);
      this.$emit("filterDisplayColumn",column)
   }

   toggleAllColumns(event:any,eventName?:string) {
      if(event && event.keyCode == 32 && this.callToggle || eventName && eventName == 'click'){
         if (this.displayAll) {
            this.displayColumn && this.displayColumn.forEach((column:any) => {
               column.display = false;
            });
            this.displayAll = false;
         } else {
            this.displayColumn && this.displayColumn.forEach((column:any) => {
               column.display = true;
            });
            this.displayAll = true;
         }
         this.$emit("filterColumns", this.displayColumn);
      }else{
         return event.preventDefault()
         }
   }

   onDropdownOpen(e:any,column?:any){
      e.preventDefault();
      if(e.keyCode == 9){
         this.tabPress = true;
         this.removeClass()
         this.$emit('tab',this.tabPress)
      }else if(e.keyCode == 13){
         this.enterPress = true;
         this.removeClass()
         this.$emit('enter',this.enterPress)
      }
      e.stopPropagation()
      this.dropdownElementList = document.querySelector('#dropdown-menu-list')
      let dropdownMenuListItems = this.dropdownElementList && this.dropdownElementList.children
      if(this.showSettings){
         const input = String.fromCharCode(e.keyCode);
         let selectAllElement = document.querySelector('#select-all-div');
         if(e.keyCode == 40){
            e.preventDefault();
            if(this.activeIndex == -1 && this.counter == 0){
               selectAllElement && selectAllElement.classList.add('dropbtn-selected');
               this.counter++;
            }
            else if(this.activeIndex < this.dropdownElementList.children.length - 1){
               this.activeIndex = this.activeIndex+1;
               dropdownMenuListItems[this.activeIndex].classList.add('dropbtn-selected');
               dropdownMenuListItems.forEach((drop:any,index: number)=>{
                  if(index!=this.activeIndex){
                     dropdownMenuListItems[index].classList.remove('dropbtn-selected');
                  }
               })
               selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
               dropdownMenuListItems[this.activeIndex].scrollIntoView(false)
            }
         }else if(e.keyCode == 38){
            e.preventDefault()
         if(this.activeIndex == -1 && this.counter == 0){
            selectAllElement && selectAllElement.classList.add('dropbtn-selected');
            this.counter++;
         }
         else if(this.activeIndex > 0){
            this.activeIndex--;
            if(this.activeIndex != -1){
               dropdownMenuListItems[this.activeIndex].classList.add('dropbtn-selected');
               dropdownMenuListItems.forEach((drop:any,index: number)=>{
                  if(index!=this.activeIndex){
                        dropdownMenuListItems[index].classList.remove('dropbtn-selected');
                  }
               })
               selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
               dropdownMenuListItems[this.activeIndex].scrollIntoView(false)
            }
         }
         else if(this.activeIndex == 0){
            this.activeIndex = -1
            selectAllElement && selectAllElement.classList.add('dropbtn-selected');
            dropdownMenuListItems.forEach((drop: any, index: number) => {
               if (index != this.activeIndex) {
                     dropdownMenuListItems[index].classList.remove('dropbtn-selected');
               }
            })
            selectAllElement && selectAllElement.scrollIntoView(false)
            let elem:any  = document.getElementById('reset-scroll') as HTMLElement ;
            elem.scrollTo(0,0);
         }
      }
         else if (/[a-zA-Z0-9]/.test(input)) {
         let iterationCount:number = 1;
            let i:number = this.activeIndex;
            selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
            while(iterationCount!=this.dropdownElementList.children.length){
               if(++i==this.dropdownElementList.children.length)
                  i=0
               let val = dropdownMenuListItems[i].innerText.charAt(0).toLowerCase();
               let inp = input.toLowerCase();
               if(val == inp){
                  let dropdownMenuItems = this.dropdownElementList.children;
                  this.activeIndex = i;
                  dropdownMenuItems[this.activeIndex].classList.add('dropbtn-selected');
                  dropdownMenuItems.forEach((drop: any, index: number) => {
                     if (index != this.activeIndex) {
                        dropdownMenuItems[index].classList.remove('dropbtn-selected');
                     }
                  })
                  dropdownMenuItems[this.activeIndex].scrollIntoView(false);
                  break;
               }              
               iterationCount++;
            }
      }
         if(e.keyCode == 32){
            e.preventDefault();
            if(this.activeIndex && this.activeIndex == 0){
               this.displayColumn[this.activeIndex].display = !this.displayColumn[this.activeIndex].display;
               this.checkAllColumns(this.displayColumn[this.activeIndex]);
            }
            else if(this.activeIndex && this.activeIndex > -1){
               this.displayColumn[this.activeIndex].display = !this.displayColumn[this.activeIndex].display;
               this.checkAllColumns(this.displayColumn[this.activeIndex]);
            }
            else if(this.activeIndex == -1 || this.activeIndex == -2 ){
               this.activeIndex = this.activeIndex == -2 ? -1 : -1;
               this.callToggle = true
               this.toggleAllColumns(e);
               this.callToggle=false
            }
         }
      }
   }  

   hoverState(type:string, index:number ){
      this.dropdownElementList = document.querySelector('#dropdown-menu-list');
      let selectAllElement = document.querySelector('#select-all-div');
      if(type == 'listItem'){
         let dropdownMenuListItems = this.dropdownElementList && this.dropdownElementList.children;
            this.activeIndex = index;
            if(this.activeIndex==0){
               dropdownMenuListItems && dropdownMenuListItems[this.activeIndex].classList.add('dropbtn-selected');
               dropdownMenuListItems && dropdownMenuListItems.forEach((drop: any, index: number) => {
               if (index != this.activeIndex) {
                  dropdownMenuListItems[index].classList.remove('dropbtn-selected');
               }
            })
            selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
            }
            else if(this.activeIndex && this.activeIndex>-1){
               dropdownMenuListItems && dropdownMenuListItems[this.activeIndex].classList.add('dropbtn-selected');
               dropdownMenuListItems && dropdownMenuListItems.forEach((drop: any, index: number) => {
                  if (index != this.activeIndex) {
                     dropdownMenuListItems[index].classList.remove('dropbtn-selected');
                  }
               })
               selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
               }
            }else if(type == 'selectAll'){
               selectAllElement && selectAllElement.classList.add('dropbtn-selected');
               let dropdownMenuListItems = this.dropdownElementList.children;
               this.activeIndex = index;
               dropdownMenuListItems.forEach((drop: any, index: number) => {
               dropdownMenuListItems[index].classList.remove('dropbtn-selected');
         })
      }
   }
      
   removeClass(){
      this.activeIndex = -1;
      this.counter =  0;
      let selectAllElement = document.querySelector('#select-all-div');
      let dropdownMenuListItems = this.dropdownElementList && this.dropdownElementList.children;
      dropdownMenuListItems && dropdownMenuListItems.forEach((drop: any, index: number) => {
      if (index != this.activeIndex) {
         dropdownMenuListItems[index].classList.remove('dropbtn-selected');
      }
      })
      selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
   }
            
}