import { Component, Prop, Vue } from 'vue-property-decorator';
import APP_UTILITIES from '@/utilities/commonFunctions';
import FixedTableComponent from '@/commoncomponents/smartTableComponents/fixedTableComponent/FixedTableComponent.vue';
import MovableTableComponent from '@/commoncomponents/smartTableComponents/movableTableComponent/MovableTableComponent.vue';
import { ScreenText } from '@/lang/ScreenText';
import PaginationComponent from '@/commoncomponents/paginationcomponent/PaginationComponent.vue';
import SortingSearchingActions from './sortingSearchingActions/SortingSearchingActions.vue';


@Component({
  components: {
    'fixed-table-component': FixedTableComponent,
    'movable-table-component': MovableTableComponent,
    'pagination': PaginationComponent,
      'sorting-searching-action':SortingSearchingActions,
  }
})
export default class SmartTableContainer extends Vue {

   @Prop({default: false})
   showEditIcon!: boolean;

  @Prop()
   headers!:any;

  @Prop()
  content!: [];

  @Prop()
  allListData!: [];

  @Prop()
  total_items!: 0;

  @Prop()
  isShow!: false

  @Prop()
  keys!: [];
  private objScreenText: ScreenText = new ScreenText();
  private deleteCell: any = '';

  @Prop()
  action!: { action: string };

  @Prop()
  sort_list!: any[];

  @Prop()
  componentName!: any[string];

  @Prop()
  tabSelected!: string;

  @Prop()
  isSearching!: boolean;

  @Prop()
  showResendModal!: boolean

  @Prop({ default: '' })
  accountName!: string;

  @Prop()
  initialPage!: number;

  @Prop()
  displayColumn!: any

   @Prop({default: false})
   isResizer!: boolean

  @Prop({ default: false })
  isSortingSearching!: boolean

  @Prop()
  payload!: any

  @Prop()
  search!: any

  @Prop({ default: function () { return { id: '', maxWidth: '', minWidth: '' } } })
  resizesData!: []

  @Prop({ default: true })
  showCheckBox!: boolean;

  @Prop({ default: false })
  limitOneCheckbox!: boolean;

   @Prop({ default: function () { return [] }})
   searchLists!: {header: string, values: any[]}[];

  @Prop({ default: 'blue' })
  theme!: string;

  @Prop()
  togglePanelActive!: boolean;

  @Prop()
   noOfRowsForMobile!:10

  @Prop()
    isMobileForAPiCall!:false

  @Prop()
   movableTableHeaders!:any

  /**
   * This property changes how the select all checkbox works;
   * instead of selecting all records from all pages, it will only consider the records on the current page.
   *
   * It works when the props `showCheckBox` is set to true and `limitOneCheckbox` is set to false
   */
  @Prop({ default: false })
  limitSelectAllToCurrentPage!: boolean;

  /**
   * Enables the items per page selector. Default is `false`
   */
  @Prop({ default: false })
  showItemsPerPageSelector!: boolean;

  public getPage: number = 1;
  public type: string = "smart-table-container";
  public header: any = {};
  public sortingDropdownStyleObj = { top: "", left: "" };
  public showPagination: boolean = false;
  public roleId: number = -1;
  public isMobileView: boolean = false;
  public hoveredTableId: string = '';
  public hoveredRowIndex: number = -1;

  /* istanbul ignore next */
   get programsArrow(){
      return ((this.roleId == 1 || this.roleId == 2) && this.componentName=='programs-table') ? true : false
   }

  /**
   * Gets the properties for the pagination component.
   *
   * If `showItemsPerPageSelector` prop is set to `true`, it is necessary to
   * check against the `payload.count` property to determine the pages to show.
   *
   * @returns An object containing the pagination properties.
   */
   get paginationProps(): { itemsPerPage: number | undefined; isShow: boolean } {
      if (this.showItemsPerPageSelector) {
        return {
          itemsPerPage: this.payload.count,
          isShow: this.total_items > this.payload.count,
        }
      }

      return { itemsPerPage: undefined, isShow: true };
   }

   /* istanbul ignore next */
   mounted(){
     const { roleId } = APP_UTILITIES.coreids();
     this.roleId = roleId;
     setTimeout(() => {
       this.showPagination = true;
     }, 500);
   }

   created(){
     this.isMobileView = APP_UTILITIES.mobileAndTabletCheck();
      if(this.isMobileView){
        document.body.classList.add("mobile-tablet-check");
      }
  }

  getDataForPage(page: number) {
    this.getPage = page;
    this.$emit('selectedPage', page);
  }

  filterColumns(displayColumn: any) {
    this.$emit("filterColumns", displayColumn);
  }

   navigateToPage(data: any){
      this.$emit('navigateToPage',data);
   }
  hoverRow(rowIndex: number, tableId: string) {
    const fixedTable = this.$refs && this.$refs.fixedTable as any;
    const movableTable = this.$refs && this.$refs.movableTable as any;
    if (tableId === 'fixedTable') {
      movableTable.hoverIndex = rowIndex;
    }
    else if (tableId === 'movableTable') {
      fixedTable.hoverIndex = rowIndex;
    }
  }

   navigateToProgram(data: any){
      this.$emit('navigateToProgram',data);
   }

   presistResize( resize: { column:any, maxWidth:any, minWidth:any }){
      this.$emit('presistResize',resize);
   }

  openSortingDropdown(header: any, coordObj?: { x: number, y: number }) {
    this.closeSortingDropdown();
    let x = coordObj && coordObj.x && coordObj.x || 0;
    let y = coordObj && coordObj.y && coordObj.y || 0;
    const xCord = (x).toString() + "px";
    const yCord = (y).toString() + "px";
    this.sortingDropdownStyleObj.left = xCord;
    this.sortingDropdownStyleObj.top = yCord;
    header.isOpen = true;
    this.header = header;
  }

  closeSortingDropdown() {
    let fixedTable = this.$refs && this.$refs.fixedTable as any;
    let movableTable = this.$refs && this.$refs.movableTable as any;
    /* istanbul ignore else */
    if (fixedTable) {
      fixedTable.handleSortingArrows(this.header);
    }
    /* istanbul ignore else */
    if (movableTable) {
      movableTable.handleSortingArrows(this.header);
    }
    this.header = {};
    this.sortingDropdownStyleObj = { top: "", left: "" };
  }

  sortData(sortObj: { sortField: string, sortDirection: number }) {
    this.$emit("sortData", sortObj);
    this.closeSortingDropdown();
  }

  searchData(searchField: any, fromClearSelection: boolean) {
    this.$emit('searchData', searchField);
      if(!fromClearSelection){
      this.closeSortingDropdown();
    }
  }

   calculateTableWidth(){
     let movableTable = this.$refs && this.$refs.movableTable as any;
     /* istanbul ignore else */
     if (movableTable) {
       movableTable.setWidth();
     }
   }

   filterDisplayColumn(column:any){
     this.$emit("filterDisplayColumn", column);
   }

   selectionChanged(data:any){
     this.$emit("selectionChanged", data);
   }

  /**
   * Handles the change event when one of the options of the dropdown component is selected.
   * Emits an `itemsPerPageChanged` event with the selected item value.
   *
   * @param numberOfItems - The number of items to display per page.
   */
   itemsPerPageChanged(numberOfItems: number): void {
     this.$emit('itemsPerPageChanged', numberOfItems);
   }
}